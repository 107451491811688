import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './LoginPage/LoginPage';
import ChatGPT from './component/chatgpt';
import BraveSearchComponent from './component/BraveSearchComponent';
import MergePDFPage from './Tools/MergePdf/MergePdf';
import SplitPdf from './Tools/SplitPdf/SplitPdf';
import CompressPdf from './Tools/CompressPdf/CompressPdf';
import PdfOcr from './Tools/PdfOcr/PdfOcr';
import PdfToJpg from './Tools/PdfToJpg/PdfToJpg';
import ImageToPdf from './Tools/ImageToPdf/ImageToPdf';
import UnlockPdf from './Tools/UnlockPdf/UnlockPdf';
import WatermarkPdf from './Tools/WatermarkPdf/WatermarkPdf';
import OfficeToPdf from './Tools/OfficeToPdf/OfficeToPdf';
import RepairPdf from './Tools/RepairPdf/RepairPdf';
import RotatePdf from './Tools/RotatePdf/RotatePdf';
import ProtectPdf from './Tools/ProtectPdf/ProtectPdf';
import PdfToPdfA from './Tools/PdfToPdfA/PdfToPdfA';
import ValidatePdfA from './Tools/ValidatePdfA/ValidatePdfA';
import ExtractPdfText from './Tools/ExtractPdfText/ExtractPdfText';
import ResizeImage from './Tools/ResizeImage/ResizeImage';
import CropImage from './Tools/CropImage/CropImage';
import CompressImage from './Tools/CompressImage/CompressImage';
import UpscaleImage from './Tools/UpscaleImage/UpscaleImage';
import RemoveBackgroundImage from './Tools/RemoveBackgroundImage/RemoveBackgroundImage';
import ConvertImage from './Tools/ConvertImage/ConvertImage';
import WatermarkImage from './Tools/WatermarkImage/WatermarkImage';
import RotateImage from './Tools/RotateImage/RotateImage';
import ToolsPage from './Tools/ToolsPage/ToolsPage';
import PageNumberPdf from './Tools/PageNumberPdf/PageNumberPdf';
import PdfTranslate from './Tools/PdfTranslate/PdfTranslate';
import SpeechToText from './Tools/SpeechToText/SpeechToText';
import SpeechToTextLive from './Tools/SpeechToTextLive/SpeechToTextLive';
import WordToPdf from './Tools/WordToPdf/WordToPdf';
import OfficialWebsites from './Tools/OfficialWebsites/OfficialWebsites';
import Forms from './Tools/Forms/Forms';
import GoogleSTT from './Tools/GoogleSTT/GoogleSTT';
import PdfToWord from './Tools/PdfToWord/PdfToWord';
import Pdf from './component/pdf';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        {/* <Route path="/lexiai" element={<Pdf />} /> */}
        {/* <Route path="/search" element={<BraveSearchComponent />} /> */}
        <Route path="/tools" element={<ToolsPage />} />
        <Route path="/Official-Websites" element={<OfficialWebsites />} />
        <Route path="/merge-pdf" element={<MergePDFPage />} />
        <Route path="/split-pdf" element={<SplitPdf />} />
        <Route path="/compress-pdf" element={<CompressPdf />} />
        <Route path="/pdf-ocr" element={<PdfOcr />} />
        {/* <Route path="/pdf-ocr-free" element={<PdfOcrFree />} /> */}
        <Route path="/pdf-jpg" element={<PdfToJpg />} />
        <Route path="/img-pdf" element={<ImageToPdf />} />
        <Route path="/unlock-pdf" element={<UnlockPdf />} />
        <Route path="/watermark-pdf" element={<WatermarkPdf />} />
        <Route path="/office-pdf" element={<OfficeToPdf />} />
        <Route path="/repair-pdf" element={<RepairPdf />} />
        <Route path="/rotate-pdf" element={<RotatePdf />} />
        <Route path="/protect-pdf" element={<ProtectPdf />} />
        <Route path="/pdf-pdfa" element={<PdfToPdfA />} />
        <Route path="/validate-pdfa" element={<ValidatePdfA />} />
        <Route path="/extract-pdf" element={<ExtractPdfText />} />
        <Route path="/resize-image" element={<ResizeImage />} />
        <Route path="/crop-image" element={<CropImage />} />
        <Route path="/compress-image" element={<CompressImage />} />
        <Route path="/upscale-image" element={<UpscaleImage />} />
        <Route path="/remove-background" element={<RemoveBackgroundImage />} />
        <Route path="/convert-image" element={<ConvertImage />} />
        <Route path="/watermark-image" element={<WatermarkImage />} />
        <Route path="/rotate-image" element={<RotateImage />} />
        <Route path="/page-number" element={<PageNumberPdf />} />
        <Route path="/translate-pdf" element={<PdfTranslate />} />
        <Route path="/speech-to-text" element={<SpeechToText />} />
        <Route path="/speech-to-text-live" element={<SpeechToTextLive />} />
        <Route path="/word-pdf" element={<WordToPdf />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/speech-translate" element={<GoogleSTT />} />
        <Route path="/pdf-word" element={<PdfToWord />} />
      </Routes>
    </Router>
  );
}

export default App;
