// import React, { useState, useRef, useEffect } from 'react';
// import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
// import { addDoc, collection, doc, getDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
// import { storage, db, auth } from '../../firebaseConfig';
// import { useNavigate } from 'react-router-dom';
// import { startRecording, stopRecording, sendAudioToGoogle } from './SpeechToTextAPI';
// import axios from 'axios';
// import backIcon from '../../assets/back.png';
// import { Document, Packer, Paragraph, TextRun } from 'docx';

// const CHATGPT_API_KEY = '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';
// const CHATGPT_API_URL = 'https://api.openai.com/v1/chat/completions';

// const GoogleSTT = () => {
//   const [transcript, setTranscript] = useState('');
//   const [translatedText, setTranslatedText] = useState('');
//   const [isRecording, setIsRecording] = useState(false);
//   const [isConvertingSTT, setIsConvertingSTT] = useState(false);
//   const [isConvertingTranslation, setIsConvertingTranslation] = useState(false);
//   const [isDownloadingTranscript, setIsDownloadingTranscript] = useState(false);
//   const [isDownloadingTranslation, setIsDownloadingTranslation] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [confirmationMessage, setConfirmationMessage] = useState('');
//   const [docxFileUrl, setDocxFileUrl] = useState(null);
//   const [isCopied, setIsCopied] = useState(false);
//   const [language, setLanguage] = useState('en-IN');
//   const [targetLanguage, setTargetLanguage] = useState('ta');
//   const [toolPrice, setToolPrice] = useState(null);
//   const [wordCount, setWordCount] = useState(0);
//   const [coinsDeducted, setCoinsDeducted] = useState(0);
//   const recorderRef = useRef(null);
//   const navigate = useNavigate();
//   const [isModalOpen, setIsModalOpen] = useState(false); 

//   useEffect(() => {
    
//     const fetchToolPrice = async () => {
//       try {
//         const toolsCollection = collection(db, 'toolsPrice');
//         const q = query(toolsCollection, where('tool', '==', 'Speech To Translation'));
//         const querySnapshot = await getDocs(q);

//         if (!querySnapshot.empty) {
//           const toolDoc = querySnapshot.docs[0];
//           setToolPrice(toolDoc.data().price);
//         } else {
//           setErrorMessage('Tool price not found.');
//         }
//       } catch (error) {
//         setErrorMessage('Error fetching tool price.');
//       }
//     };

//     fetchToolPrice();
//   }, []);

  
//   const translateTextWithChatGPT = async (text, targetLang) => {
//     setIsConvertingTranslation(true);
//     const prompt = `Translate the following text to ${targetLang}: ${text}`;

//     try {
//       const response = await axios.post(
//         CHATGPT_API_URL,
//         {
//           model: "gpt-4",
//           messages: [
//             { role: "system", content: "Translate text precisely and fully." },
//             { role: "user", content: prompt }
//           ],
//           max_tokens: 500
//         },
//         {
//           headers: {
//             'Authorization': `Bearer ${CHATGPT_API_KEY}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       const translatedText = response.data.choices[0].message.content.trim();
//       setIsConvertingTranslation(false);
//       return translatedText;
//     } catch (error) {
//       setIsConvertingTranslation(false);
//       setErrorMessage('Translation failed. Please try again later.');
//       console.error('Error with ChatGPT Translation:', error);
//       return '';
//     }
//   };

  
//     const confirmRecording = () => {
//         setIsModalOpen(true);
//     };

//     const handleStartRecording = async () => {
//         const userId = auth.currentUser?.uid;
      
//         if (!userId) {
//           setErrorMessage("User is not authenticated. Please log in.");
//           return;
//         }
      
//         try {
          
//           const userDocRef = doc(db, 'users', userId);
//           const userDoc = await getDoc(userDocRef);
      
//           if (userDoc.exists()) {
//             const currentBalance = userDoc.data().balance || 0;
      
            
//             if (currentBalance >= 50) {
              
//               setIsModalOpen(false);
//               await startGoogleRecording();
//             } else {
//               setIsModalOpen(false);
//               setErrorMessage("Insufficient balance. To start recording, your wallet must have at least 50 coins. Recharge your wallet in lexdoo app to proceed.");
//             }
//           } else {
//             setIsModalOpen(false);
//             setErrorMessage("User document not found.");
//           }
//         } catch (error) {
//           setIsModalOpen(false);
//           setErrorMessage("Error fetching user balance. Please try again.");
//         }
//       };
      

// const startGoogleRecording = async () => {
//     setErrorMessage('');
//     setConfirmationMessage('');
//     const userId = auth.currentUser?.uid;
//     if (!userId) {
//       setErrorMessage("User is not authenticated. Please log in.");
//       return;
//     }

//     try {
//       const { recorder } = await startRecording(setIsRecording);
//       recorderRef.current = recorder;

//       recorderRef.current.addEventListener(
//         'dataavailable',
//         async (event) => {
//           const audioBlob = event.data;
//           if (audioBlob && audioBlob.size > 0) {
//             setIsConvertingSTT(true);
//             try {
//               const transcription = await sendAudioToGoogle(audioBlob, language);
//               let words = transcription.split(/\s+/);
//               let processedTranscript = "";
//               let totalDeductedCoins = 0;

//               for (let i = 0; i < words.length; i += 50) {
//                 const segmentWords = words.slice(i, i + 50).join(" ");
//                 processedTranscript += ` ${segmentWords}`;

//                 const userDocRef = doc(db, 'users', userId);
//                 const userDoc = await getDoc(userDocRef);
//                 const currentBalance = userDoc.data().balance || 0;

//                 // Deduct coins incrementally for each 50-word segment
//                 if (currentBalance >= toolPrice) {
//                   await updateDoc(userDocRef, { balance: currentBalance - toolPrice });
//                   totalDeductedCoins += toolPrice;
//                 } else {
//                   setErrorMessage("Insufficient balance. Transcription stopped.");
//                   break;
//                 }
//               }

//               setTranscript(processedTranscript.trim());
//               setCoinsDeducted(totalDeductedCoins);

              
//               if (totalDeductedCoins > 0) {
//                 const translated = await translateTextWithChatGPT(processedTranscript, targetLanguage);
//                 setTranslatedText(translated);
//               }

//             } catch (error) {
//               setErrorMessage('Error processing transcription or translation: ' + error.message);
//             } finally {
//               setIsConvertingSTT(false);
//             }
//           } else {
//             setErrorMessage('No audio data available for transcription.');
//           }
//         },
//         { once: true }
//       );
//     } catch (error) {
//       setErrorMessage('Failed to start recording: ' + error.message);
//       setIsConvertingSTT(false);
//     }
// };

  

//   const stopGoogleRecording = () => {
//     if (recorderRef.current) {
//       recorderRef.current.stop();
//       setIsRecording(false);
//       recorderRef.current = null;
//     }
//   };
  

 
//   const handleLanguageChange = (event) => {
//     setLanguage(event.target.value);
//   };

  
//   const handleTargetLanguageChange = (event) => {
//     setTargetLanguage(event.target.value);
//   };

  
//   const uploadDocxToFirebase = async (buffer) => {
//     const fileName = `transcript_${Date.now()}.docx`;
//     const storageRef = ref(storage, `transcripts/${fileName}`);

//     try {
//       const snapshot = await uploadBytes(storageRef, buffer);
//       const downloadUrl = await getDownloadURL(snapshot.ref);

//       await addDoc(collection(db, 'transcripts'), {
//         downloadUrl,
//         timestamp: new Date(),
//       });

//       return downloadUrl;
//     } catch (error) {
//       throw new Error('Failed to upload DOCX to Firebase.');
//     }
//   };

  
//   const downloadAsDocx = async (text, filename, isDownloadingStateSetter) => {
//     setIsCopied(false); // Reset "Copy Link" state
//     isDownloadingStateSetter(true); // Start download
//     const doc = new Document({
//       sections: [
//         {
//           children: [
//             new Paragraph({
//               children: [new TextRun(text)],
//             }),
//           ],
//         },
//       ],
//     });

//     try {
//       const buffer = await Packer.toBlob(doc);
//       const downloadUrl = await uploadDocxToFirebase(buffer);
//       setDocxFileUrl(downloadUrl);
//       setConfirmationMessage(`${filename} downloaded successfully.`);
//     } catch (error) {
//       setErrorMessage('An error occurred during DOCX generation.');
//     } finally {
//       isDownloadingStateSetter(false); // End download
//     }
//   };

  
//   const copyLinkToClipboard = async () => {
//     if (!docxFileUrl) {
//       setErrorMessage('No link available to copy.');
//       return;
//     }

//     try {
//       await navigator.clipboard.writeText(docxFileUrl);
//       setIsCopied(true);
//       setConfirmationMessage('DOCX file link copied to clipboard!');
//     } catch (error) {
//       setErrorMessage('Failed to copy link.');
//     }
//   };

//   return (
//     <>
//       <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
//         <div className="back-button">
//           <img src={backIcon} alt='back-btn' style={{ width: '25px', height: '25px' }} />
//           <button className='back-sub'>Back</button>
//         </div>
//       </div>
//       <div className="speech-to-text-container" style={styles.container}>
//         <h1>Speech to Translated Text</h1>
//         <p>Convert speech to translated text and download as DOCX</p>

//         <p style={{ color: 'red', marginTop: '20px' }} className="noteStt">
//             Note: To start recording, your wallet must have at least 50 coins.
//       </p>

//         <div style={styles.languageContainer}>
//           <label htmlFor="language-select" style={styles.label}>Choose Speech Recognition Language: </label>
//           <select id="language-select" value={language} onChange={(e) => setLanguage(e.target.value)} style={styles.inputField}>
           
//             <option value="en-IN">English (India)</option>
//             <option value="ta-IN">Tamil (India)</option>
//             <option value="te-IN">Telugu (India)</option>
//             <option value="ml-IN">Malayalam (India)</option>
//             <option value="kn-IN">Kannada (India)</option>
//             <option value="hi-IN">Hindi (India)</option>
//             <option value="mr-IN">Marathi (India)</option>
//             <option value="gu-IN">Gujarati (India)</option>
//           </select>
//         </div>

//         <div style={styles.languageContainer}>
//           <label htmlFor="target-language-select" style={styles.label}>Choose Translation Language: </label>
//           <select id="target-language-select" value={targetLanguage} onChange={(e) => setTargetLanguage(e.target.value)} style={styles.inputField}>
//             <option value="en">English (India)</option>
//             <option value="ta">Tamil (India)</option>
//             <option value="te">Telugu (India)</option>
//             <option value="ml">Malayalam (India)</option>
//             <option value="kn">Kannada (India)</option>
//             <option value="hi">Hindi (India)</option>
//             <option value="mr">Marathi (India)</option>
//             <option value="gu">Gujarati (India)</option>
            
//           </select>
//         </div>

//         <div style={styles.buttonsContainer}>
//           {!isRecording && (
//             <button onClick={confirmRecording} style={styles.button}>
//               Start Recording
//             </button>
//           )}
//           {isRecording && (
//             <button onClick={stopGoogleRecording} style={styles.button}>
//               Stop Recording
//             </button>
//           )}
//         </div>

//         {errorMessage && <p style={{ color: 'red', marginTop: '25px' }}>{errorMessage}</p>}

//         {wordCount > 0 && (
//           <p style={{ color: 'green', marginTop: '25px' }}>Words Count: {wordCount} | Coins Deducted: {coinsDeducted}</p>
//         )}



//         <div style={styles.resultContainer}>
//           <h3>Original Transcript:</h3>
//           <textarea
//             value={transcript}
//             onChange={(e) => setTranscript(e.target.value)}
//             style={styles.textarea}
//             rows={5}
//             placeholder={isConvertingSTT ? 'Converting...' : 'Transcript will appear here...'}
//           />
//           {transcript && (
//             <div style={styles.downloadButtonContainer}>
//               <button
//                 onClick={() => downloadAsDocx(transcript, 'Original Transcript', setIsDownloadingTranscript)}
//                 style={styles.button}
//                 disabled={isDownloadingTranscript}
//               >
//                 {isDownloadingTranscript ? 'Downloading...' : 'Download Original Transcript as DOCX'}
//               </button>
//             </div>
//           )}
//         </div>

//         <div style={styles.resultContainer}>
//           <h3>Translated Text:</h3>
//           <textarea
//             value={translatedText}
//             onChange={(e) => setTranslatedText(e.target.value)}
//             style={styles.textarea}
//             rows={5}
//             placeholder={isConvertingTranslation ? 'Translating...' : 'Translation will appear here...'}
//           />
//           {translatedText && (
//             <div style={styles.downloadButtonContainer}>
//               <button
//                 onClick={() => downloadAsDocx(translatedText, 'Translated Text', setIsDownloadingTranslation)}
//                 style={styles.button}
//                 disabled={isDownloadingTranslation}
//               >
//                 {isDownloadingTranslation ? 'Downloading...' : 'Download Translated Text as DOCX'}
//               </button>
//             </div>
//           )}
//         </div>

//         {docxFileUrl && (
//           <div className='share-options'>
//             <button onClick={copyLinkToClipboard} className='share-button'>
//               {isCopied ? 'Copied!' : 'Copy Link'}
//             </button>
//           </div>
//         )}

//         {confirmationMessage && <p style={{ color: 'green', marginTop: '25px' }}>{confirmationMessage}</p>}

//         {isModalOpen && (
//         <div className="modal">
//             <div className="modal-content">
//             <p>For every 50 words, {toolPrice} coins will be deducted from your wallet. Do you want to proceed?</p>
//             <div className="modal-buttons">
//                 <button className="modal-confirm" onClick={handleStartRecording}>Ok</button>
//                 <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>Cancel</button>
//             </div>
//             </div>
//         </div>
//         )}
//       </div>
//     </>
//   );
// };

// const styles = {
//   container: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     padding: '60px 30px',
//   },
//   buttonsContainer: {
//     margin: '20px',
//   },
//   button: {
//     padding: '10px 20px',
//     fontSize: '16px',
//     cursor: 'pointer',
//     backgroundColor: '#e74c3c',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '5px',
//     margin: '5px',
//   },
//   resultContainer: {
//     marginTop: '20px',
//     padding: '10px',
//     border: '1px solid #ddd',
//     borderRadius: '5px',
//     maxWidth: '600px',
//     width: '100%',
//     textAlign: 'left',
//   },
//   textarea: {
//     width: '100%',
//     padding: '10px',
//     fontSize: '14px',
//     borderRadius: '5px',
//     border: '1px solid #ddd',
//     resize: 'vertical',
//   },
//   languageContainer: {
//     marginBottom: '10px',
//     width: '100%',
//     marginTop: '30px',
//     display: 'flex',
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//   },
//   label: {
//     display: 'block',
//     fontSize: '14px',
//     fontWeight: 'bold',
//   },
//   inputField: {
//     width: '72%',
//     padding: '10px',
//     fontSize: '14px',
//     borderRadius: '5px',
//     border: '1px solid #ddd',
//     backgroundColor: '#fff',
//   },
// };

// export default GoogleSTT;




import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, doc, getDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { storage, db, auth } from '../../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { startRecording, stopRecording, sendAudioToGoogle } from './SpeechToTextAPI';
import axios from 'axios';
import backIcon from '../../assets/back.png';
import { Document, Packer, Paragraph, TextRun } from 'docx';

const CHATGPT_API_KEY = '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';
const CHATGPT_API_URL = 'https://api.openai.com/v1/chat/completions';

const GoogleSTT = () => {
  const [transcript, setTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isConvertingSTT, setIsConvertingSTT] = useState(false);
  const [isConvertingTranslation, setIsConvertingTranslation] = useState(false);
  const [isDownloadingTranscript, setIsDownloadingTranscript] = useState(false);
  const [isDownloadingTranslation, setIsDownloadingTranslation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [docxFileUrl, setDocxFileUrl] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [language, setLanguage] = useState('en-IN');
  const [targetLanguage, setTargetLanguage] = useState('ta');
  const [toolPrice, setToolPrice] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [coinsDeducted, setCoinsDeducted] = useState(0);
  const recorderRef = useRef(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [isMobile, setIsMobile] = useState(false);
  const [recognitionInstance, setRecognitionInstance] = useState(null);

  useEffect(() => {
    // Check if the browser supports the Web Speech API
    if (!('webkitSpeechRecognition' in window)) {
      setErrorMessage('Speech recognition not supported in this browser.');
      return;
    }
  
    // Function to handle the window resize event
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);  // Update state to indicate it's a mobile device
      } else {
        setIsMobile(false); // Update state to indicate it's a desktop device
      }
    };
  
    // Set initial state based on the current window size
    handleResize();
  
    // Add the resize event listener
    window.addEventListener('resize', handleResize);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    
    const fetchToolPrice = async () => {
      try {
        const toolsCollection = collection(db, 'toolsPrice');
        const q = query(toolsCollection, where('tool', '==', 'Speech To Translation'));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const toolDoc = querySnapshot.docs[0];
          setToolPrice(toolDoc.data().price);
        } else {
          setErrorMessage('Tool price not found.');
        }
      } catch (error) {
        setErrorMessage('Error fetching tool price.');
      }
    };

    fetchToolPrice();
  }, []);

  let recognition;
if (window.SpeechRecognition || window.webkitSpeechRecognition) {
  const SpeechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  recognition = new SpeechRecognition();
  recognition.lang = 'en-IN'; // Default language
  recognition.interimResults = true; // Set to true if you want real-time results
  recognition.continuous = true;
}

const mapSpokenWordsToSymbols = (text) => {
  // English mappings
  text = text
    .replace(/full stop/gi, '.')  
    .replace(/comma/gi, ', ')     
    .replace(/question mark/gi, '? ') 
    .replace(/exclamation mark/gi, '! ') 
    .replace(/colon/gi, ': ')     
    .replace(/semicolon/gi, '; ') 
    .replace(/dash/gi, '- ')     
    .replace(/open parenthesis/gi, ' (') 
    .replace(/close parenthesis/gi, ') ') 
    .replace(/quotation mark/gi, '"')    
    .replace(/next line/gi, '\n')    
    .replace(/ellipsis/gi, '... ');    

  // Tamil mappings (without \b)
  text = text
    .replace(/முற்றுப்புள்ளி/gi, '.')  
    .replace(/காற்புள்ளி/gi, ', ')            
    .replace(/கேள்விக்குறி/gi, '? ')   
    .replace(/வியப்புக்குறி/gi, '! ')    
    .replace(/முக்காற்புள்ளி/gi, ': ')          
    .replace(/அரைப்புள்ளி/gi, '; ')     
    .replace(/இடைக்கோடு/gi, '- ')           
    // .replace(/திறந்த\s?கோlam/gi, ' (') 
    // .replace(/மூடிய\s?கோlam/gi, ') ')  
    .replace(/இரட்டைமேற்கோள் குறிகள்/gi, '"') 
    .replace(/தொக்கிக்குறி/gi, '... ') 
    .replace(/புதிய\s?கோடு/gi, '\n'); 
    
  return text;
};


// Start Speech Recognition
const startWebkitRecording = () => {
  if (!('webkitSpeechRecognition' in window)) return;

  const recognition = new window.webkitSpeechRecognition();

  recognition.continuous = true; // Keep listening continuously
  recognition.interimResults = true; // Show real-time results
  recognition.lang = language; // Set the selected language

  recognition.start();
  setIsRecording(true);
  setErrorMessage('');
  setConfirmationMessage('');
  setRecognitionInstance(recognition); // Save the recognition instance

  recognition.onresult = (event) => {
    let interimResult = '';
    for (let i = event.resultIndex; i < event.results.length; ++i) {
      let transcriptPart = event.results[i][0].transcript;

      // Handle special cases where user pronounces punctuation
      transcriptPart = mapSpokenWordsToSymbols(transcriptPart);

      if (event.results[i].isFinal) {
        setTranscript((prev) => prev + transcriptPart + ' ');
        setInterimTranscript(''); // Clear interim when final result is received
      } else {
        interimResult += transcriptPart;
      }
    }
    setInterimTranscript(interimResult); // Set interim transcript for real-time feedback
  };

  recognition.onerror = (event) => {
    setErrorMessage('Error occurred in recognition: ' + event.error);
    stopRecording(); // Stop recording when error occurs
  };

  // Restart recognition on end if user is still speaking
  recognition.onend = () => {
    if (isRecording) {
      recognition.start(); // Restart recognition
    }
  };
};


// const stopWebkitRecording = () => {
//   if (recognitionInstance) {
//     recognitionInstance.stop();
//     setIsRecording(false);

//     // Trigger translation logic
//     if (transcript.trim()) {
//       translateTextWithChatGPT(transcript, targetLanguage)
//         .then((translated) => {
//           setTranslatedText(translated);
//           setConfirmationMessage('Translation complete!');
//         })
//         .catch((error) => {
//           setErrorMessage('Translation failed. Please try again.');
//         });
//     } else {
//       setErrorMessage('No transcript available for translation.');
//     }
//   }
// };


const stopWebkitRecording = async () => {
  if (recognitionInstance) {
    recognitionInstance.stop();
    setIsRecording(false);

    if (transcript.trim()) {
      const userId = auth.currentUser?.uid;

      if (!userId) {
        setErrorMessage("User is not authenticated. Please log in.");
        return;
      }

      try {
        const count = transcript.trim().split(/\s+/).length; // Calculate word count
        setWordCount(count); // Update the word count state

        const coinsRequired = Math.ceil(count / 50) * 4; // Calculate coins required (4 coins for 50 words)

        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const currentBalance = userDoc.data().balance || 0;

          if (currentBalance >= coinsRequired) {
            await updateDoc(userDocRef, { balance: currentBalance - coinsRequired });
            setCoinsDeducted(coinsRequired);
            setConfirmationMessage(`Recording stopped. ${coinsRequired} coins deducted from your wallet. Translation started`);

            // Trigger translation logic
            const translated = await translateTextWithChatGPT(transcript, targetLanguage);
            setTranslatedText(translated);
            setConfirmationMessage('Translation complete!');
          } else {
            setErrorMessage('Insufficient balance. Please recharge your wallet.');
          }
        } else {
          setErrorMessage('User document not found.');
        }
      } catch (error) {
        setErrorMessage('Error processing coin deduction or translation. Please try again.');
      }
    } else {
      setErrorMessage('No transcript available for translation.');
    }
  }
};





  
  const translateTextWithChatGPT = async (text, targetLang) => {
    setIsConvertingTranslation(true);
    const prompt = `Translate the following text to ${targetLang}: ${text}`;

    try {
      const response = await axios.post(
        CHATGPT_API_URL,
        {
          model: "gpt-4",
          messages: [
            { role: "system", content: "Translate text precisely and fully." },
            { role: "user", content: prompt }
          ],
          max_tokens: 500
        },
        {
          headers: {
            'Authorization': `Bearer ${CHATGPT_API_KEY}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const translatedText = response.data.choices[0].message.content.trim();
      setIsConvertingTranslation(false);
      return translatedText;
    } catch (error) {
      setIsConvertingTranslation(false);
      setErrorMessage('Translation failed. Please try again later.');
      console.error('Error with ChatGPT Translation:', error);
      return '';
    }
  };

  
    const confirmRecording = () => {
        setIsModalOpen(true);
    };

    const handleStartRecording = async () => {
      const userId = auth.currentUser?.uid;
    
      if (!userId) {
        setErrorMessage("User is not authenticated. Please log in.");
        return;
      }
    
      try {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
    
        if (userDoc.exists()) {
          const currentBalance = userDoc.data().balance || 0;
    
          if (currentBalance >= 50) {
            setIsModalOpen(false); // Close the modal
            startWebkitRecording(); // Start Webkit Speech Recognition
          } else {
            setIsModalOpen(false);
            setErrorMessage(
              "Insufficient balance. To start recording, your wallet must have at least 50 coins. Recharge your wallet in the Lexdoo app to proceed."
            );
          }
        } else {
          setIsModalOpen(false);
          setErrorMessage("User document not found.");
        }
      } catch (error) {
        setIsModalOpen(false);
        setErrorMessage("Error fetching user balance. Please try again.");
      }
    };
    
    
      

const startGoogleRecording = async () => {
    setErrorMessage('');
    setConfirmationMessage('');
    const userId = auth.currentUser?.uid;
    if (!userId) {
      setErrorMessage("User is not authenticated. Please log in.");
      return;
    }

    try {
      const { recorder } = await startRecording(setIsRecording);
      recorderRef.current = recorder;

      recorderRef.current.addEventListener(
        'dataavailable',
        async (event) => {
          const audioBlob = event.data;
          if (audioBlob && audioBlob.size > 0) {
            setIsConvertingSTT(true);
            try {
              const transcription = await sendAudioToGoogle(audioBlob, language);
              let words = transcription.split(/\s+/);
              let processedTranscript = "";
              let totalDeductedCoins = 0;

              for (let i = 0; i < words.length; i += 50) {
                const segmentWords = words.slice(i, i + 50).join(" ");
                processedTranscript += ` ${segmentWords}`;

                const userDocRef = doc(db, 'users', userId);
                const userDoc = await getDoc(userDocRef);
                const currentBalance = userDoc.data().balance || 0;

                // Deduct coins incrementally for each 50-word segment
                if (currentBalance >= toolPrice) {
                  await updateDoc(userDocRef, { balance: currentBalance - toolPrice });
                  totalDeductedCoins += toolPrice;
                } else {
                  setErrorMessage("Insufficient balance. Transcription stopped.");
                  break;
                }
              }

              setTranscript(processedTranscript.trim());
              setCoinsDeducted(totalDeductedCoins);

              
              if (totalDeductedCoins > 0) {
                const translated = await translateTextWithChatGPT(processedTranscript, targetLanguage);
                setTranslatedText(translated);
              }

            } catch (error) {
              setErrorMessage('Error processing transcription or translation: ' + error.message);
            } finally {
              setIsConvertingSTT(false);
            }
          } else {
            setErrorMessage('No audio data available for transcription.');
          }
        },
        { once: true }
      );
    } catch (error) {
      setErrorMessage('Failed to start recording: ' + error.message);
      setIsConvertingSTT(false);
    }
};

  

  const stopGoogleRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stop();
      setIsRecording(false);
      recorderRef.current = null;
    }
  };
  

 
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  
  const handleTargetLanguageChange = (event) => {
    setTargetLanguage(event.target.value);
  };

  
  const uploadDocxToFirebase = async (buffer) => {
    const fileName = `transcript_${Date.now()}.docx`;
    const storageRef = ref(storage, `transcripts/${fileName}`);

    try {
      const snapshot = await uploadBytes(storageRef, buffer);
      const downloadUrl = await getDownloadURL(snapshot.ref);

      await addDoc(collection(db, 'transcripts'), {
        downloadUrl,
        timestamp: new Date(),
      });

      return downloadUrl;
    } catch (error) {
      throw new Error('Failed to upload DOCX to Firebase.');
    }
  };

  
  const downloadAsDocx = async (text, filename, isDownloadingStateSetter) => {
    setIsCopied(false); // Reset "Copy Link" state
    isDownloadingStateSetter(true); // Start download
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [new TextRun(text)],
            }),
          ],
        },
      ],
    });

    try {
      const buffer = await Packer.toBlob(doc);
      const downloadUrl = await uploadDocxToFirebase(buffer);
      setDocxFileUrl(downloadUrl);
      setConfirmationMessage(`${filename} downloaded successfully.`);
    } catch (error) {
      setErrorMessage('An error occurred during DOCX generation.');
    } finally {
      isDownloadingStateSetter(false); // End download
    }
  };

  
  const copyLinkToClipboard = async () => {
    if (!docxFileUrl) {
      setErrorMessage('No link available to copy.');
      return;
    }

    try {
      await navigator.clipboard.writeText(docxFileUrl);
      setIsCopied(true);
      setConfirmationMessage('DOCX file link copied to clipboard!');
    } catch (error) {
      setErrorMessage('Failed to copy link.');
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '0px' }} onClick={() => navigate('/tools')}>
        <div className="back-button">
          <img src={backIcon} alt='back-btn' style={{ width: '25px', height: '25px' }} />
          <button className='back-sub'>Back</button>
        </div>
      </div>
      <div className="speech-to-text-container" style={styles.container}>
        <h1>Speech to Translated Text</h1>
        <p>Convert speech to translated text and download as DOCX</p>

        <p style={{ color: 'red', marginTop: '20px' }} className="noteStt">
            Note: To start recording, your wallet must have at least 50 coins.
      </p>

        <div style={styles.languageContainer}>
          <label htmlFor="language-select" style={styles.label}>Choose Speech Recognition Language: </label>
          <select id="language-select" value={language} onChange={(e) => setLanguage(e.target.value)} style={styles.inputField}>
           
            <option value="en-IN">English (India)</option>
            <option value="ta-IN">Tamil (India)</option>
            <option value="te-IN">Telugu (India)</option>
            <option value="ml-IN">Malayalam (India)</option>
            <option value="kn-IN">Kannada (India)</option>
            <option value="hi-IN">Hindi (India)</option>
            <option value="mr-IN">Marathi (India)</option>
            <option value="gu-IN">Gujarati (India)</option>
          </select>
        </div>

        <div style={styles.languageContainer}>
          <label htmlFor="target-language-select" style={styles.label}>Choose Translation Language: </label>
          <select id="target-language-select" value={targetLanguage} onChange={(e) => setTargetLanguage(e.target.value)} style={styles.inputField}>
            <option value="en">English (India)</option>
            <option value="ta">Tamil (India)</option>
            <option value="te">Telugu (India)</option>
            <option value="ml">Malayalam (India)</option>
            <option value="kn">Kannada (India)</option>
            <option value="hi">Hindi (India)</option>
            <option value="mr">Marathi (India)</option>
            <option value="gu">Gujarati (India)</option>
            
          </select>
        </div>

        <div style={styles.buttonsContainer}>
          {!isRecording && (
            <button onClick={confirmRecording} style={styles.button}>
              Start Recording
            </button>
          )}
          {isRecording && (
            <button onClick={stopWebkitRecording} style={styles.button}>
              Stop Recording
            </button>
          )}
          {isMobile && (
          <p style={{ color: 'green', marginTop: '30px' }} className="noteStt">
            Note: For better performance use it in desktop
          </p>
        )}
        </div>



        {errorMessage && <p style={{ color: 'red', marginTop: '25px' }}>{errorMessage}</p>}

        {coinsDeducted > 0 && (
          <p style={{ color: 'green', marginTop: '25px' }}>
            {/* Words Count: {wordCount} |  */}
            Coins Deducted: {coinsDeducted} coins
          </p>
        )}




        <div style={styles.resultContainer}>
          <h3>Original Transcript:</h3>
          <textarea
            value={transcript + interimTranscript}
            onChange={(e) => setTranscript(e.target.value)}
            style={styles.textarea}
            rows={5}
            placeholder={isConvertingSTT ? 'Converting...' : 'Transcript will appear here...'}
          />
          <p style={styles.interim} id="interim">{interimTranscript}</p>
          {transcript && (
            <div style={styles.downloadButtonContainer}>
              <button
                onClick={() => downloadAsDocx(transcript, 'Original Transcript', setIsDownloadingTranscript)}
                style={styles.button}
                disabled={isDownloadingTranscript}
              >
                {isDownloadingTranscript ? 'Downloading...' : 'Download Original Transcript as DOCX'}
              </button>
            </div>
          )}
        </div>

        <div style={styles.resultContainer}>
          <h3>Translated Text:</h3>
          <textarea
            value={translatedText}
            onChange={(e) => setTranslatedText(e.target.value)}
            style={styles.textarea}
            rows={5}
            placeholder={isConvertingTranslation ? 'Translating...' : 'Translation will appear here...'}
          />
          {translatedText && (
            <div style={styles.downloadButtonContainer}>
              <button
                onClick={() => downloadAsDocx(translatedText, 'Translated Text', setIsDownloadingTranslation)}
                style={styles.button}
                disabled={isDownloadingTranslation}
              >
                {isDownloadingTranslation ? 'Downloading...' : 'Download Translated Text as DOCX'}
              </button>
            </div>
          )}
        </div>

        {docxFileUrl && (
          <div className='share-options'>
            <button onClick={copyLinkToClipboard} className='share-button'>
              {isCopied ? 'Copied!' : 'Copy Link'}
            </button>
          </div>
        )}

        {confirmationMessage && <p style={{ color: 'green', marginTop: '25px' }}>{confirmationMessage}</p>}

        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <p>
                For every 50 words, {toolPrice} coins will be deducted from your wallet. Do you want to proceed?
              </p>
              <div className="modal-buttons">
                <button className="modal-confirm" onClick={handleStartRecording}>
                  Ok
                </button>
                <button className="modal-cancel" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px 30px',
  },
  buttonsContainer: {
    margin: '20px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#e74c3c',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    margin: '5px',
  },
  resultContainer: {
    marginTop: '20px',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    maxWidth: '600px',
    width: '100%',
    textAlign: 'left',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    resize: 'vertical',
  },
  languageContainer: {
    marginBottom: '10px',
    width: '100%',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    display: 'block',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  inputField: {
    width: '72%',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
  },
};

export default GoogleSTT;
