import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from '../firebaseConfig';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import axios from 'axios';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import Tesseract from 'tesseract.js';
import { jsPDF } from 'jspdf';
import mammoth from 'mammoth';
import { Document, Packer, Paragraph, TextRun, AlignmentType } from 'docx';
import { saveAs } from 'file-saver';
import './chatgpt.css';
import LoginPage from '../LoginPage/LoginPage';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage imports
import { startRecording, stopRecording, sendAudioToGoogle } from './SpeechToTextAPI';

import pdfIcon from '../assets/pdf.png';
import copyIcon from '../assets/copy-icon.png';
import docxIcon from '../assets/docx.png';
import fileUploadIcon from '../assets/attachment.png';
import speakerIcon from '../assets/speaker-icon.png';
import muteIcon from '../assets/mute-icon.png';
import micIcon from '../assets/mic-on.png';
import micMuteIcon from '../assets/mic-off.png';
import sendIcon from '../assets/lexi-send.png';
import logoutIcon from '../assets/logout.png';
import menuIcon from '../assets/menu.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import tools from '../assets/tools.png';

import { GoogleGenerativeAI } from "@google/generative-ai";
import { marked } from 'marked';
import { getVoiceList, speakText } from './textToSpeech';

const STT_API_KEY = 'AIzaSyC8HBUJaTEK_Re23cxfjKtCGMQEw-NkRuc';
const API_KEY = 'AIzaSyD1GR1jvSxXuPOFE8Zx3BUiQ1o4I4-04G0';
const MAX_RETRIES = 3;

const ChatGPT = () => {
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [currentSpeechText, setCurrentSpeechText] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [userId, setUserId] = useState(null);
  const [isValidSubscription, setIsValidSubscription] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const fileInputRef = useRef(null);
  const scrollViewRef = useRef();
  const navigate = useNavigate();
  const recognitionRef = useRef(null); // Use ref to store the recognition instance
  const [isRecording, setIsRecording] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileInfo, setFileInfo] = useState({ url: '', type: '' });
  const [disableSendButton, setDisableSendButton] = useState(false); // Disable send button state
  const [showSendButton, setShowSendButton] = useState(true); // State to control send button visibility
  const [audioStream, setAudioStream] = useState(null);
  const [showListeningModal, setShowListeningModal] = useState(false);
  const [modalText, setModalText] = useState('Listening...');
  const [selectedLanguage, setSelectedLanguage] = useState('en-US');
  const [showAttachModal, setShowAttachModal] = useState(false);


  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const AttachmentOptionsModal = ({ isOpen, onClose, onLexiAiClick, onContinueClick }) => {
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-content" style={{maxWidth: '430px'}}>
        <h3 style={{marginBottom: 10}}>Choose an option</h3>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
          <button onClick={onLexiAiClick} style={{ margin: '10px', padding: '10px 15px' }}>Pdf Analyzer</button>
          <button onClick={onContinueClick} style={{ margin: '10px', padding: '10px 15px' }}>Attachments</button>
          <button onClick={onClose} style={{ margin: '10px', padding: '10px 15px' }}>Close</button>
          </div>
        </div>
      </div>
    );
  };

  
  const handleAttachmentClick = () => {
    setShowAttachModal(true); // Only show the attachment options modal
  };
  
  // Updated handleContinueOption function to open the file selector
  const handleContinueOption = () => {
    fileInputRef.current.click(); // Trigger the file input when "Continue" is selected
    setShowAttachModal(false); // Close the modal after opening the file input
  };


  const handleSupportClick = () => {
    // Navigate directly to the contact page
    window.open("https://lexdoo.com/contact.html", "_blank");
  };

 
  const handleMergePdfClick = () => {
    navigate('/tools');
  };
  const handleSearchPdfClick = () => {
    navigate('/search');
  };

  const handlePdfClick = () => {
    navigate('/lexiai');
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

    if (storedUserId && storedIsLoggedIn) {
      setUserId(storedUserId);
      loadChats(storedUserId);
      checkSubscriptionStatus(storedUserId);
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        localStorage.setItem('userId', user.uid);
        localStorage.setItem('isLoggedIn', 'true');
        loadChats(user.uid);
        checkSubscriptionStatus(user.uid);
      } else {
        setUserId(null);
        localStorage.removeItem('userId');
        localStorage.removeItem('isLoggedIn');
        setMessages([]);
      }
    });

    const baseUrl = `${window.location.origin}`;
    window.history.replaceState({}, document.title, baseUrl);

    return () => unsubscribe();
  }, []);

  const checkSubscriptionStatus = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        
        // Check if the session token matches
        const localSessionToken = localStorage.getItem('sessionToken');
        const firestoreSessionToken = userData.sessionToken;
        
        if (localSessionToken !== firestoreSessionToken) {
          // If session token doesn't match, force logout
          await signOut(auth);
          setIsValidSubscription(false);
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('sessionToken');
          alert("You've been logged out because you logged in from another device.");
          return false;
        }
  
        // Check if subscription is valid
        const subscriptionEndDate = new Date(userData.subscriptionEndDate.seconds * 1000);
        const currentDate = new Date();
        if (subscriptionEndDate <= currentDate) {
          setIsValidSubscription(false);
          localStorage.removeItem('isLoggedIn');
          localStorage.setItem('subscriptionExpired', 'true');
          signOut(auth);
          return false;
        }
        return true;
      } else {
        setIsValidSubscription(false);
        localStorage.removeItem('isLoggedIn');
        localStorage.setItem('subscriptionExpired', 'true');
        signOut(auth);
        return false;
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
      setIsValidSubscription(false);
      localStorage.setItem('subscriptionExpired', 'true');
      return false;
    }
  };
  
  const loadChats = async (uid) => {
    try {
      const docRef = doc(db, 'LexiAiChats', uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setMessages(data.chats);
      } else {
        console.log('No previous chats found');
      }
    } catch (error) {
      console.error('Error loading chats:', error);
    }
  };

  const saveChats = async (newMessages) => {
    if (!userId) return;

    try {
      const docRef = doc(db, 'LexiAiChats', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await updateDoc(docRef, {
          chats: [...docSnap.data().chats, ...newMessages],
        });
      } else {
        await setDoc(docRef, {
          chats: newMessages,
        });
      }
    } catch (error) {
      console.error('Error saving chats:', error);
    }
  };

  // Initialize the Speech Recognition API
  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

  useEffect(() => {
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continuous = true; // Continue listening
      recognition.interimResults = true; // Show real-time results
      recognition.lang = 'en-US'; // Language setting

      recognition.onresult = (event) => {
        let finalTranscript = '';

        // Process the speech results
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            // Add the final result to the final transcript
            finalTranscript += event.results[i][0].transcript;
          }
        }

        // Set final transcript to inputText
        setInputText((prevText) => prevText + finalTranscript);
      };

      recognition.onerror = (event) => {
        console.error('Error occurred in recognition: ' + event.error);
      };

      recognition.onend = () => {
        console.log('Speech recognition stopped.');
        setIsRecording(false); // Update recording state
        setIsConverting(false); // Reset converting state
      };

      recognitionRef.current = recognition; // Store the recognition instance
    }
  }, []);

  const handleStartRecording = async (language) => {
    try {
      setIsConverting(true);
      setModalText('Listening...');  // Show "Listening" text in the modal
      const { recorder } = await startRecording(setIsRecording, language);  // Pass language here
      recognitionRef.current = recorder;
  
      recorder.addEventListener('dataavailable', async (event) => {
        const audioBlob = event.data;
        const transcription = await sendAudioToGoogle(audioBlob, language);  // Pass language here
  
        setInputText((prevText) => prevText + ' ' + transcription);
        setModalText('Converting...');  // Show converting text in the modal
        setIsConverting(false);
        setShowListeningModal(false);  // Close the modal once transcription is done
      });
    } catch (error) {
      console.error('Error during recording:', error);
      setIsConverting(false);
      setShowListeningModal(false);  // Close the modal in case of error
    }
  };
  
  const handleStopRecording = () => {
    if (recognitionRef.current) {
      stopRecording(recognitionRef.current);  // Stop recording
      setIsRecording(false);  // Update recording state
      setModalText('Please wait while converting text...');  // Update modal text
      setShowListeningModal(true);  // Keep the modal open for the wait message
    }
  };
  
  
  

  const toggleRecording = () => {
    if (isRecording) {
      handleStopRecording();  // Stop recording and show "Please wait"
      setModalText('Please wait while converting text...');
      setShowListeningModal(true);  // Keep the modal open for the wait message
    } else {
      setModalText('Select language and start recording');
      setShowListeningModal(true);  // Show the modal to select the language and start recording
    }
  };
  
  

  // const toggleRecording = () => {
  //   if (isRecording) {
  //     recognitionRef.current.stop(); // Stop speech recognition
  //     setIsRecording(false); // Show mic icon
  //   } else {
  //     recognitionRef.current.start(); // Start speech recognition
  //     setIsRecording(true); // Show mute icon
  //     setIsConverting(true); // Show "Converting text..." in placeholder
  //   }
  // };

  // const startRecording = async () => {
  //   try {
  //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  //     setAudioStream(stream);
  //     setIsRecording(true);
  
  //     const audioChunks = [];
  //     const mediaRecorder = new MediaRecorder(stream);
      
  //     mediaRecorder.ondataavailable = event => {
  //       audioChunks.push(event.data);
  //     };
  //     mediaRecorder.onstop = async () => {
  //       const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
  //       const reader = new FileReader();
  //       reader.readAsDataURL(audioBlob);
  //       reader.onloadend = async () => {
  //         const base64Audio = reader.result.split(',')[1]; // Extract base64
  
  //         // Send the base64 audio to Firebase function hosted in a different project
  //         const response = await axios.post('https://us-central1-lexdo-7b0b1.cloudfunctions.net/speechToText', 
  //           { audio: base64Audio }, {
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //             withCredentials: true, // Ensure this is set for cross-origin requests
  //           }
  //         );
  //         const { transcript } = response.data;
  
  //         if (transcript) {
  //           setInputText(prevText => prevText + transcript); // Add transcript to input field
  //         }
  //       };
  //     };
  
  //     mediaRecorder.start();
  //     recognitionRef.current = mediaRecorder;
  //   } catch (error) {
  //     console.error('Error starting audio recording:', error);
  //   }
  // };  
  

  // // Stop recording function
  // const stopRecording = () => {
  //   if (recognitionRef.current) {
  //     recognitionRef.current.stop();
  //   }
  //   setAudioStream(null);
  //   setIsRecording(false);
  // };

  // const toggleRecording = () => {
  //   if (isRecording) {
  //     stopRecording();
  //   } else {
  //     startRecording();
  //   }
  // };

  // const handleSend = async () => {
  //   if (!inputText.trim() && selectedFiles.length === 0) {
  //     return;
  //   }

  //   const subscriptionValid = await checkSubscriptionStatus(userId);
  //   if (!subscriptionValid) {
  //     return;
  //   }

  //   let newMessage = {
  //     text: inputText || 'Processing file...',
  //     sender: 'user',
  //     timestamp: new Date().toISOString(),
  //     fileType: selectedFiles.length === 1 ? selectedFiles[0].type : null,
  //     fileName: selectedFiles.length === 1 ? selectedFiles[0].name : null,
  //   };

  //   if (selectedFiles.length === 1) {
  //     const file = selectedFiles[0];
  //     if (file.type.startsWith('image/')) {
  //       newMessage.filePreview = URL.createObjectURL(file);
  //     } else if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
  //       newMessage.filePreview = newMessage.fileType === 'application/pdf' ? pdfIcon : docxIcon;
  //     }
  //   }

  //   setMessages((prevMessages) => [...prevMessages, newMessage]);
  //   setInputText('');
  //   setIsTyping(true);

  //   let combinedText = inputText;

  //   if (selectedFiles.length > 0) {
  //     const extractedText = await handleFileProcessing(selectedFiles, inputText);
  //     combinedText = `${extractedText}\n\n${inputText}`;
  //   }

  //   // Keep a conversation history of the last few messages
  //   const conversationHistory = getConversationHistory(messages);
  //   const finalQuery = `${conversationHistory}\n\nUser: ${combinedText}`;

  //   // Add instruction for Gemini to only respond to legal and audit-related queries
  //   const instruction = "You are an assistant that provides helpful responses to only legal and audit-related queries and shouldn't responds to out of the scope questions. Focus on providing clear, concise, and accurate information. If a user uploads a document, explain its content or draft a response based on the user's request. try to eloborate and provide contact and address. if user asked to translate the provided content in preferred language. provide full translated content without omiting any content.";

  //   // Check if the input query is related to legal or real-time information
  //   const isLegalOrRealTimeQuery = checkIfLegalOrRealTimeQuery(combinedText);

  //   let queryToSend = `${instruction}\n\n${finalQuery}`;

  //   // If it is a legal or real-time query, fetch additional information and summarize
  //   if (isLegalOrRealTimeQuery) {
  //     const legalInfo = await fetchLegalInformation(combinedText);
  //     const summarizedInfo = await summarizeLegalInformation(legalInfo);
  //     queryToSend = summarizedInfo ? `${summarizedInfo}\n\n${instruction}\n\n${finalQuery}` : queryToSend;
  //   }

  //   // Send the final query with conversation history to Gemini
  //   const botMessage = await sendTextToGemini(queryToSend);

  //   if (botMessage) {
  //     const allMessages = [newMessage, botMessage];
  //     setMessages((prevMessages) => [...prevMessages, botMessage]);
  //     await saveChats(allMessages);
  //   }

  //   setSelectedFiles([]);
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = '';
  //   }

  //   setIsTyping(false);
  // };

  // without restriction


  // const handleSend = async () => {
  //   if (!inputText.trim() && selectedFiles.length === 0) {
  //     return;
  //   }

  //   const subscriptionValid = await checkSubscriptionStatus(userId);
  //   if (!subscriptionValid) {
  //     return;
  //   }

  //   let newMessage = {
  //     text: inputText || 'Processing file...',
  //     sender: 'user',
  //     timestamp: new Date().toISOString(),
  //     fileType: selectedFiles.length === 1 ? selectedFiles[0].type : null,
  //     fileName: selectedFiles.length === 1 ? selectedFiles[0].name : null,
  //   };

  //   if (selectedFiles.length === 1) {
  //     const file = selectedFiles[0];
  //     if (file.type.startsWith('image/')) {
  //       newMessage.filePreview = URL.createObjectURL(file);
  //     } else if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
  //       newMessage.filePreview = newMessage.fileType === 'application/pdf' ? pdfIcon : docxIcon;
  //     }
  //   }

  //   setMessages((prevMessages) => [...prevMessages, newMessage]);
  //   setInputText('');
  //   setIsTyping(true);

  //   let combinedText = inputText;

  //   if (selectedFiles.length > 0) {
  //     const extractedText = await handleFileProcessing(selectedFiles, inputText);
  //     combinedText = `${extractedText}\n\n${inputText}`;
  //   }

  //   // Keep a conversation history of the last few messages
  //   const conversationHistory = getConversationHistory(messages);
  //   const finalQuery = `${conversationHistory}\n\nUser: ${combinedText}`;

  //   // Add instruction for Gemini to focus on legal and audit queries
  //   const instruction = "You are an assistant that provides helpful responses to only legal,address,contact information,help,finance,translation,contact and audit-related queries and shouldn't respond to out of scope questions. Focus on providing clear, concise, and accurate information. If a user uploads a document, explain its content or draft a response based on the user's request.If anyone ask who developed you tell The AI was developed by a skilled team of developers and AI specialists at Lexdoo. If anyone ask what is Lexdoo and what is your name tell The AI was developed by a skilled team of developers and AI specialists at Lexdoo.If anyone tells incident and ask what to do or help please tell them detailly if they need police station contact information tell that also";

  //   // Fetch legal information and summarize it for all inputs
  //   const legalInfo = await fetchLegalInformation(combinedText);
  //   const summarizedInfo = await summarizeLegalInformation(legalInfo);

  //   let queryToSend = summarizedInfo ? `${summarizedInfo}\n\n${instruction}\n\n${finalQuery}` : `${instruction}\n\n${finalQuery}`;

  //   // Send the final query with conversation history to Gemini
  //   const botMessage = await sendTextToGemini(queryToSend);

  //   if (botMessage) {
  //     const allMessages = [newMessage, botMessage];
  //     setMessages((prevMessages) => [...prevMessages, botMessage]);
  //     await saveChats(allMessages);
  //   }

  //   setSelectedFiles([]);
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = '';
  //   }

  //   setIsTyping(false);
  // };

//   const handleSend = async () => {
//     if (!inputText.trim() && selectedFiles.length === 0) {
//       return;
//     }
  
//     const subscriptionValid = await checkSubscriptionStatus(userId);
//     if (!subscriptionValid) {
//       return;
//     }
  
//     let newMessage = {
//       text: inputText || 'Processing file...',
//       sender: 'user',
//       timestamp: new Date().toISOString(),
//       fileType: selectedFiles.length === 1 ? selectedFiles[0].type : null,
//       fileName: selectedFiles.length === 1 ? selectedFiles[0].name : null,
//       fileUrl: '', // This will be filled after the file is uploaded
//     };
  
//     if (selectedFiles.length === 1) {
//       const file = selectedFiles[0];
//       let fileUrl = '';
  
//       if (file.type.startsWith('image/')) {
//         newMessage.filePreview = URL.createObjectURL(file);
//       } else if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
//         newMessage.filePreview = newMessage.fileType === 'application/pdf' ? pdfIcon : docxIcon;
//       }
  
//       // Upload the file and get its URL
//       const blob = file;
//       const filename = `${new Date().getTime()}_${file.name}`;
//       fileUrl = await uploadFileToFirebase(blob, filename);
//       newMessage.fileUrl = fileUrl; // Save the file URL to the message
//     }
  
//     setMessages((prevMessages) => [...prevMessages, newMessage]);
//     await saveChats([newMessage]);
  
//     setInputText('');
//     setIsTyping(true); // Mark typing as true before further processing
  
//     let combinedText = inputText;
  
//     if (selectedFiles.length > 0) {
//       const extractedText = await handleFileProcessing(selectedFiles, inputText);
//       combinedText = `${extractedText}\n\n${inputText}`;
//     }
  
//     // Skip the legal information fetching and summarizing, send directly to Gemini
//     const conversationHistory = getConversationHistory(messages);
//     const finalQuery = `${conversationHistory}\n\nUser: ${combinedText}`;
//     // Add instruction for Gemini to focus on legal and audit queries
// //     const instruction = `
// // {
// //   "instruction": "You are Lexi, an advanced AI assistant for resolving legal, audit, and financial queries. ",
// // }  `;

  

// //   const queryToSend = `${instruction}\n\n${finalQuery}`;

//   // Send the final query with conversation history to Gemini
//   const botMessage = await sendTextToGemini(finalQuery);

//   if (botMessage) {
//     const allMessages = [newMessage, botMessage];
//     setMessages((prevMessages) => [...prevMessages, botMessage]);
//     await saveChats(allMessages);
//   }

//   setSelectedFiles([]);
//   if (fileInputRef.current) {
//     fileInputRef.current.value = '';
//   }

//   setIsTyping(false); // Set typing to false after processing
// };
  
// const handleSend = async () => {
//   if (!inputText.trim()) return;

//   // Add user message to chat
//   const userMessage = {
//     text: inputText,
//     sender: 'user',
//     timestamp: new Date().toISOString(),
//   };
//   setMessages((prevMessages) => [...prevMessages, userMessage]);

//   // Send the message to the backend (Firebase function)
//   try {
//     const response = await axios.post('https://us-central1-lexdo-7b0b1.cloudfunctions.net/generateContent', { message: inputText });

//     // Handle the AI's response
//     const aiResponse = response.data.response;

//     // Extract the text from the parts array
//     const botMessageText = aiResponse.parts && aiResponse.parts.length > 0 ? aiResponse.parts[0].text : "No response";

//     // Add AI response to chat
//     const botMessage = {
//       text: botMessageText,
//       sender: 'Lexi',
//       timestamp: new Date().toISOString(),
//     };

//     setMessages((prevMessages) => [...prevMessages, botMessage]);
//   } catch (error) {
//     console.error('Error fetching response:', error);
//   }

//   // Clear input field
//   setInputText('');
// };

const retryWithDelay = async (fn, retries = MAX_RETRIES, delayTime = 2000) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await fn();  // Try executing the function
    } catch (error) {
      if (error.response && (error.response.status === 429 || error.response.status === 500)) {
        const retryDelay = delayTime * Math.pow(2, i);  // Exponential backoff
        console.warn(`Error ${error.response.status}. Retrying in ${retryDelay / 1000} seconds...`);
        await Delay(retryDelay);  // Delay before retrying
      } else {
        throw error;  // If another error occurs, throw it
      }
    }
  }
  throw new Error('Max retries reached');
};


// Delay function
const Delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const handleSend = async () => {
  if (!inputText.trim() && selectedFiles.length === 0) {
    return;
  }
  setShowSendButton(false); // Hide the send button when sending starts
  setDisableSendButton(true); // Disable the send button when the send process starts


  const subscriptionValid = await checkSubscriptionStatus(userId);
  if (!subscriptionValid) {
    return;
  }

  let newMessage = {
    text: inputText || 'Processing file...',
    sender: 'user',
    timestamp: new Date().toISOString(),
    fileType: selectedFiles.length === 1 ? selectedFiles[0].type : null,
    fileName: selectedFiles.length === 1 ? selectedFiles[0].name : null,
    fileUrl: '', // This will be filled after the file is uploaded
  };

  if (selectedFiles.length === 1) {
    const file = selectedFiles[0];
    let fileUrl = '';

    if (file.type.startsWith('image/')) {
      newMessage.filePreview = URL.createObjectURL(file);
    } else if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      newMessage.filePreview = newMessage.fileType === 'application/pdf' ? pdfIcon : docxIcon;
    }

    // Upload the file and get its URL
    const blob = file;
    const filename = `${new Date().getTime()}_${file.name}`;
    fileUrl = await uploadFileToFirebase(blob, filename);
    newMessage.fileUrl = fileUrl; // Save the file URL to the message
  }

  setMessages((prevMessages) => [...prevMessages, newMessage]);
  await saveChats([newMessage]);

  setInputText('');
  setIsTyping(true); // Mark typing as true before further processing

  let combinedText = inputText;

  if (selectedFiles.length > 0) {
    const extractedText = await handleFileProcessing(selectedFiles, inputText);
    combinedText = `${extractedText}\n\n${inputText}`;
  }

  const conversationHistory = getConversationHistory(messages);
  const finalQuery = `${conversationHistory}\n\nUser: ${combinedText}`;

  // Retry with delay for the function that calls Firebase function
  try {
    const botMessage = await retryWithDelay(() => axios.post(
      'https://us-central1-lexdo-7b0b1.cloudfunctions.net/generateContent', 
      { message: finalQuery }
    ));

    const aiResponse = botMessage.data.response;
    let botMessageText = aiResponse.parts && aiResponse.parts.length > 0 ? aiResponse.parts[0].text : "No response";
    botMessageText = botMessageText
    .replace(/\*\*\s?([^\*]+)\*\*/g, '<b>$1</b>')  // Replace **word** with <b>word</b> for bolding
    .replace(/\*\s/g, '<br>')
    .replace(/' '/g, '<br>')  // Two spaces means a single new line
    .replace(/'  '\s/g, '<br>') // One space means a double new line
    .replace(/(?:\r\n|\r|\n)/g, '<br>')        // Replace new lines with <br>

    
    const botMessageFormatted = {
      text: botMessageText,
      sender: 'Lexi',
      timestamp: new Date().toISOString(),
    };

    setMessages((prevMessages) => [...prevMessages, botMessageFormatted]);
    await saveChats([newMessage, botMessageFormatted]);

    setIsTyping(false);
    setShowSendButton(true); // Show the send button after processing

     // Wait for 10 seconds before enabling the send button again
     await delay(10000);
     
     setDisableSendButton(false); // Enable the send button after the delay

  } catch (error) {
    console.error('Error fetching response:', error);
          setShowSendButton(true); // Show the send button if an error occurs

  }

  setSelectedFiles([]);
  setIsTyping(false); // Set typing to false after processing
};

// const handleSend = async () => {
//   if (!inputText.trim() && selectedFiles.length === 0) {
//     return;
//   }

//   const subscriptionValid = await checkSubscriptionStatus(userId);
//   if (!subscriptionValid) {
//     return;
//   }

//   let newMessage = {
//     text: inputText || 'Processing file...',
//     sender: 'user',
//     timestamp: new Date().toISOString(),
//     fileType: selectedFiles.length === 1 ? selectedFiles[0].type : null,
//     fileName: selectedFiles.length === 1 ? selectedFiles[0].name : null,
//     fileUrl: '', // This will be filled after the file is uploaded
//   };

//   if (selectedFiles.length === 1) {
//     const file = selectedFiles[0];
//     let fileUrl = '';

//     if (file.type.startsWith('image/')) {
//       newMessage.filePreview = URL.createObjectURL(file);
//     } else if (file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
//       newMessage.filePreview = newMessage.fileType === 'application/pdf' ? pdfIcon : docxIcon;
//     }

//     // Upload the file and get its URL
//     const blob = file;
//     const filename = `${new Date().getTime()}_${file.name}`;
//     fileUrl = await uploadFileToFirebase(blob, filename);
//     newMessage.fileUrl = fileUrl; // Save the file URL to the message
//   }

//   setMessages((prevMessages) => [...prevMessages, newMessage]);
//   await saveChats([newMessage]);

//   setInputText('');
//   setIsTyping(true); // Mark typing as true before further processing

//   let combinedText = inputText;

//   if (selectedFiles.length > 0) {
//     const extractedText = await handleFileProcessing(selectedFiles, inputText);
//     combinedText = `${extractedText}\n\n${inputText}`;
//   }

//   // Send conversation history and user input to Firebase function
//   const conversationHistory = getConversationHistory(messages);
//   const finalQuery = `${conversationHistory}\n\nUser: ${combinedText}`;
  
//   try {
//     const response = await axios.post('https://us-central1-lexdo-7b0b1.cloudfunctions.net/generateContent', { message: finalQuery });

//         const aiResponse = response.data.response;

//     // Extract the text from the parts array
//     const botMessageText = aiResponse.parts && aiResponse.parts.length > 0 ? aiResponse.parts[0].text : "No response";

//     const botMessage = {
//       text: botMessageText,
//       sender: 'Lexi',
//       timestamp: new Date().toISOString(),
//     };

//     setMessages((prevMessages) => [...prevMessages, botMessage]);
//     await saveChats([newMessage, botMessage]);
//   } catch (error) {
//     console.error('Error fetching response:', error);
//   }

//   setSelectedFiles([]);
//   setIsTyping(false); // Set typing to false after processing
// };


  const handleFileProcessing = async (files) => {
    try {
      setIsTyping(true);
      let extractedText = '';

      for (let file of files) {
        if (file.type === 'application/pdf') {
          extractedText += await extractTextFromPDF(file);
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          extractedText += await extractTextFromDOCX(file);
        } else if (file.type.startsWith('image/')) {
          extractedText += await extractTextFromImage(file);
        } else {
          alert('Unsupported file type. Please upload a PDF, DOCX, or image.');
          return '';
        }
      }

      return extractedText;
    } catch (error) {
      console.error('Error processing file:', error);
      alert('Failed to process the file.');
      return '';
    }
  };

  // const extractTextFromPDF = async (file) => {
  //   try {
  //     const reader = new FileReader();
  //     return new Promise((resolve) => {
  //       reader.onload = async (e) => {
  //         const typedArray = new Uint8Array(e.target.result);
  //         const pdf = await pdfjsLib.getDocument(typedArray).promise;
  //         let text = '';

  //         for (let i = 0; i < pdf.numPages; i++) {
  //           const page = await pdf.getPage(i + 1);
  //           const textContent = await page.getTextContent();
  //           const pageText = textContent.items.map((item) => item.str).join(' ');

  //           if (!pageText.trim()) {
  //             const viewport = page.getViewport({ scale: 2.0 });
  //             const canvas = document.createElement('canvas');
  //             const context = canvas.getContext('2d');
  //             canvas.height = viewport.height;
  //             canvas.width = viewport.width;

  //             await page.render({ canvasContext: context, viewport }).promise;
  //             const imageData = canvas.toDataURL('image/png');
  //             const ocrResult = await extractTextFromImage(imageData);
  //             text += ocrResult + '\n\n';
  //           } else {
  //             text += pageText + '\n\n';
  //           }
  //         }

  //         console.log('Extracted Text from PDF:', text);
  //         resolve(text);
  //       };
  //       reader.readAsArrayBuffer(file);
  //     });
  //   } catch (error) {
  //     console.error('Error extracting text from PDF:', error);
  //     throw new Error('Failed to extract text from PDF.');
  //   }
  // };

  const extractTextFromPDF = async (file) => {
    try {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = async (e) => {
          const typedArray = new Uint8Array(e.target.result);
          const pdf = await pdfjsLib.getDocument(typedArray).promise;
          let text = '';
  
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const textContent = await page.getTextContent();
            const pageText = textContent.items.map((item) => item.str).join(' ');
  
            if (!pageText.trim()) {
              const viewport = page.getViewport({ scale: 2.0 });
              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;
  
              await page.render({ canvasContext: context, viewport }).promise;
              const imageData = canvas.toDataURL('image/png');
              const ocrResult = await extractTextFromImage(imageData);
              text += ocrResult + '\n\n';
            } else {
              text += pageText + '\n\n';
            }
          }
  
          resolve(text);
        };
  
        reader.onerror = () => reject(new Error('Error reading the PDF file.'));
        reader.readAsArrayBuffer(file);
      });
    } catch (error) {
      console.error('Error extracting text from PDF:', error);
      throw new Error('Failed to extract text from PDF.');
    }
  };
  

  const extractTextFromImage = (file) => {
    return new Promise((resolve, reject) => {
      Tesseract.recognize(file, 'eng', {
        logger: (m) => console.log(m),
      })
        .then(({ data: { text } }) => {
          console.log('Extracted Text:', text); // Log the extracted text here
          resolve(text);
        })
        .catch((error) => {
          console.error('Error extracting text from image:', error);
          reject('Failed to extract text from image.');
        });
    });
  };

  const extractTextFromDOCX = async (file) => {
    try {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const result = await mammoth.extractRawText({ arrayBuffer });
          resolve(result.value);
        };
        reader.readAsArrayBuffer(file);
      });
    } catch (error) {
      console.error('Error extracting text from DOCX:', error);
      throw new Error('Failed to extract text from DOCX.');
    }
  };

  const fetchLegalInformation = async (query) => {
    try {
      const response = await axios.get('https://us-central1-lexdo-7b0b1.cloudfunctions.net/braveSearchAPI/api/search', {
        params: {
          q: query,
          count: 10,
          search_lang: 'en',
          safesearch: 'moderate',
          summary: 1,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching data from Brave Search API:', error);
      return null;
    }
  };

  const summarizeLegalInformation = async (legalInfo) => {
    if (!legalInfo) return null;

    const summarizerKey = legalInfo.summarizer?.key;
    if (!summarizerKey) return null;

    try {
      const summaryResponse = await axios.get('https://us-central1-lexdo-7b0b1.cloudfunctions.net/braveSearchAPI/api/summarizer', {
        params: {
          key: summarizerKey,
          entity_info: 1,
        },
      });

      const cleanSummary = summaryResponse.data.summary
        .map((msg) => msg.data)
        .join(' ')
        .replace(/\*\*/g, '') // Remove asterisks used for bolding
        .replace(/(?:\r\n|\r|\n)/g, '<br>'); // Replace new lines with <br> tags

      return cleanSummary;
    } catch (error) {
      console.error('Error summarizing legal information:', error);
      return null;
    }
  };

  const sendTextToGemini = async (text, retries = MAX_RETRIES) => {
    const genAI = new GoogleGenerativeAI(API_KEY);
    // const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
    //gemini-1.5-pro-exp-0827
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro-exp-0827" });

    try {
      const result = await model.generateContent(text);
      let botResponse = result.response.text();
      botResponse = botResponse.replace(/\*/g, '');

      const newResponse = {
        text: botResponse,
        sender: 'Lexi',
        timestamp: new Date().toISOString(),
      };
      
      return newResponse;
    } catch (error) {
      if (error.response && error.response.status === 429 && retries > 0) {
        const delayTime = 2000 * Math.pow(2, MAX_RETRIES - retries);
        console.warn(`Rate limit hit, retrying in ${delayTime / 1000} seconds...`);
        await delay(delayTime);
        return sendTextToGemini(text, retries - 1);
      }
      console.error('Error fetching response from Gemini API:', error);
      alert('Failed to fetch response from the assistant');
      return null;
    }
  };

  const audioRef = useRef(null);  // This will store the current Audio instance

const toggleSpeech = async (text) => {
  if (isSpeaking && currentSpeechText === text) {
    // If already speaking the same text, stop the current audio
    if (audioRef.current) {
      audioRef.current.pause();  // Pause the audio
      audioRef.current.currentTime = 0;  // Reset to the start
    }
    setIsSpeaking(false);
    setCurrentSpeechText('');
  } else {
    // If not currently speaking or different text, start the speech
    try {
      const audioUrl = await speakText(text);  // Get the audio URL from Google TTS
      if (audioRef.current) {
        // If an audio is already playing, stop it first
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      const audio = new Audio(audioUrl);
      audioRef.current = audio;  // Store the current audio instance

      audio.play();
      setIsSpeaking(true);
      setCurrentSpeechText(text);

      audio.onended = () => {
        setIsSpeaking(false);
        setCurrentSpeechText('');
      };
    } catch (error) {
      console.error('Error in speech:', error);
      alert('Failed to generate speech.');
    }
  }
};

  // const copyToClipboard = (text) => {
  //   navigator.clipboard.writeText(text);
  //   alert('Copied to Clipboard');
  // };

  const copyToClipboard = (text) => {
    // Remove HTML-like tags and ensure proper formatting
    const processedText = text
      .replace(/<b>(.*?)<\/b>/g, '$1')        // Remove bold tags, but keep the text inside
      .replace(/<br>/g, '\n')                 // Convert <br> tags to newlines
      .replace(/(?:\r\n|\r|\n)/g, '\n');       // Ensure proper line breaks
  
    navigator.clipboard.writeText(processedText).then(() => {
      alert('Text copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy to clipboard:', err);
    });
  };
  
  

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    const imageFiles = files.filter((file) => file.type.startsWith('image/'));
    const documentFiles = files.filter(
      (file) => file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );

    if (documentFiles.length > 1) {
      alert('You can only upload one PDF or DOCX file at a time.');
      return;
    }

    setSelectedFiles([...imageFiles, ...documentFiles]);
  };

  const clearSelectedFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    if (updatedFiles.length === 0 && fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const uploadFileToFirebase = async (blob, filename) => {
    const storageRef = ref(storage, `documents/${filename}`);
    try {
      const snapshot = await uploadBytes(storageRef, blob);
      return await getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error('Failed to upload file to Firebase:', error);
      throw new Error('Failed to upload file to Firebase.');
    }
  };
  

  // const generatePDF = async (message) => {
  //   const doc = new jsPDF({
  //     orientation: 'portrait',
  //     unit: 'mm',
  //     format: 'a4',
  //   });
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   const margin = 20;
  //   const maxWidth = pageWidth - 2 * margin;
  //   const lineHeight = 10;
  //   let cursorY = 20;
  
    
  //   doc.setFontSize(12);
  
   
  //   const text = message.text.replace(/\*/g, '')
    
  
    
  //   const lines = doc.splitTextToSize(text, maxWidth);
  
  //   lines.forEach((line) => {
  //     if (cursorY + lineHeight > pageHeight - margin) {
  //       doc.addPage();
  //       cursorY = margin; 
  //     }
  //     doc.text(line, margin, cursorY);
  //     cursorY += lineHeight;
  //   });
  
  //   const blob = doc.output('blob');
  //   const filename = `response_${new Date().getTime()}.pdf`;
  //   const url = await uploadFileToFirebase(blob, filename);
  //   setFileInfo({ url: url, type: 'PDF' });
  //   setShowModal(true);
  //   console.log('PDF uploaded, URL:', url);
    
  //   navigator.clipboard.writeText(url).then(() => {
  //     console.log('Link copied to clipboard!');
  //   }).catch(err => {
  //     console.error('Failed to copy to clipboard:', err);
  //     console.log('Failed to copy link to clipboard.');
  //   });
  // };
  
  
  const generatePDF = async (message) => {
    const doc = new jsPDF();
    const margin = 20;
    const pageWidth = doc.internal.pageSize.width - 2 * margin;
    let cursorY = margin;
    const lineHeight = 7;  // Reduced line height for tighter spacing

    doc.setFontSize(12);

    // Replace <br> with actual newlines and handle <b> for bold text
    const text = message.text
      .replace(/\*\*\s?([^\*]+)\*\*/g, '<b>$1</b>')  // Handle bolding by replacing **word** with <b>word</b>
      .replace(/<br>/g, '\n')                        // Replace <br> with a newline
      .replace(/(?:\r\n|\r|\n)/g, '\n');             // Handle regular newlines

    const lines = text.split('\n');  // Split text by newline

    lines.forEach((line) => {
      // Handle bold text
      if (/<b>(.*?)<\/b>/.test(line)) {
        const parts = line.split(/<b>|<\/b>/);
        parts.forEach((part, index) => {
          if (index % 2 === 1) {
            doc.setFont('helvetica', 'bold');  // Apply bold font
          } else {
            doc.setFont('helvetica', 'normal');  // Apply normal font
          }

          // Wrap text within the page width
          const wrappedLines = doc.splitTextToSize(part, pageWidth);
          wrappedLines.forEach((wrappedLine) => {
            if (cursorY + lineHeight > doc.internal.pageSize.height - margin) {
              doc.addPage();
              cursorY = margin;
            }
            doc.text(wrappedLine, margin, cursorY);
            cursorY += lineHeight;
          });
        });
      } else {
        doc.setFont('helvetica', 'normal');
        // Wrap text within the page width
        const wrappedLines = doc.splitTextToSize(line, pageWidth);
        wrappedLines.forEach((wrappedLine) => {
          if (cursorY + lineHeight > doc.internal.pageSize.height - margin) {
            doc.addPage();
            cursorY = margin;
          }
          doc.text(wrappedLine, margin, cursorY);
          cursorY += lineHeight;
        });
      }
    });

    const blob = doc.output('blob');
    const filename = `response_${new Date().getTime()}.pdf`;
    const url = await uploadFileToFirebase(blob, filename);
    setFileInfo({ url: url, type: 'PDF' });
    setShowModal(true);
    console.log('PDF uploaded, URL:', url);

    navigator.clipboard.writeText(url).then(() => {
      console.log('Link copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy link to clipboard:', err);
    });
};

  
  

  // const generateDOCX = async (message) => {
    
  //   const paragraphs = message.text.replace(/\*/g, '').split('\n').map((line) => {
  //     return new Paragraph({
  //       alignment: AlignmentType.LEFT,
  //       spacing: { after: 200 },
  //       children: [
  //         new TextRun(line.trim()),
  //       ],
  //     });
  //   });
  
  //   const doc = new Document({
  //     sections: [
  //       {
  //         children: paragraphs,
  //       },
  //     ],
  //   });
  
  //   try {
  //     const blob = await Packer.toBlob(doc);
  //     const filename = `response_${new Date().getTime()}.docx`;
  
  //     const url = await uploadFileToFirebase(blob, filename);
  //     setFileInfo({ url: url, type: 'DOCX' });
  //     setShowModal(true);
  //     console.log('DOCX uploaded, URL:', url);
      
  //     navigator.clipboard.writeText(url).then(() => {
  //       console.log('Link copied to clipboard!');
  //     });
  //   } catch (error) {
  //     console.error('Error generating or uploading DOCX:', error);
  //     console.log('Failed to generate or upload DOCX file.');
  //   }
  // };
  
  const generateDOCX = async (message) => {
    const paragraphs = message.text.replace(/\*\*\s?([^\*]+)\*\*/g, '<b>$1</b>') // Handle bold **text**
      .replace(/<br>/g, '\n') // Replace <br> with newline
      .split('\n').map((line) => {
        const children = [];
  
        // Split by <b> tags to apply bold formatting
        const parts = line.split(/<b>|<\/b>/);
        parts.forEach((part, index) => {
          const run = new TextRun({
            text: part.trim(),
            bold: index % 2 === 1, // Bold if the part is between <b> tags
          });
          children.push(run);
        });
  
        return new Paragraph({
          alignment: AlignmentType.LEFT,
          spacing: { after: 200 }, // Adjust spacing after paragraphs
          children,
        });
      });
  
    const doc = new Document({
      sections: [
        {
          children: paragraphs,
        },
      ],
    });
  
    try {
      const blob = await Packer.toBlob(doc);
      const filename = `response_${new Date().getTime()}.docx`;
  
      const url = await uploadFileToFirebase(blob, filename); // Upload the file to Firebase
      setFileInfo({ url: url, type: 'DOCX' });
      setShowModal(true);
      console.log('DOCX uploaded, URL:', url);
  
      navigator.clipboard.writeText(url).then(() => {
        console.log('Link copied to clipboard!');
      });
    } catch (error) {
      console.error('Error generating or uploading DOCX:', error);
    }
  };
  

  const linkify = (text) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])|(\b([a-z0-9.-]+)\.(com|gov|org|in|net)([-A-Z0-9+&@#\/%=~_|]*))/gi;
    return text.replace(urlPattern, (url) => {
      // Check if the URL starts with http/https, if not, add it
      const formattedUrl = url.startsWith('http') ? url : `http://${url}`;
      return `<a href="${formattedUrl}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };
  
  

  const isNewDay = (currentMessage, previousMessage) => {
    if (!previousMessage) return true;

    const currentDate = new Date(currentMessage.timestamp).setHours(0, 0, 0, 0);
    const previousDate = new Date(previousMessage.timestamp).setHours(0, 0, 0, 0);

    return currentDate !== previousDate;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setIsValidSubscription(false);
      localStorage.removeItem('isLoggedIn');
    } catch (error) {
      console.error('Error during logout:', error);
      alert('Failed to logout. Please try again.');
    }
  };

  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (!isValidSubscription) {
    return <LoginPage />;
  }

  const ListeningModal = ({ 
    isOpen, 
    text, 
    onStartRecording, 
    onStopRecording, 
    isRecording, 
    selectedLanguage, 
    setSelectedLanguage 
  }) => {
    if (!isOpen) return null;
  
    const handleLanguageChange = (event) => {
      setSelectedLanguage(event.target.value);  // Update the selected language
    };
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          {!isRecording && (
            <>
              <p>{text}</p>
              {/* Styled language selection */}
              <div style={{ marginTop: '10px', textAlign: 'center' }}>
                <label htmlFor="language-select" style={{ fontWeight: 'bold', marginRight: '5px' }}>Select Language:</label>
                <select 
                  id="language-select" 
                  value={selectedLanguage} 
                  onChange={handleLanguageChange} 
                  style={{
                    padding: '5px', 
                    borderRadius: '5px', 
                    border: '1px solid #ccc', 
                    backgroundColor: '#f9f9f9',
                    fontSize: '14px',
                  }}
                >
                  <option value="en-US">English (US)</option>
                  <option value="ta-IN">Tamil (IN)</option>
                  <option value="te-IN">Telugu (IN)</option>
                  <option value="kn-IN">Kannada (IN)</option>
                  <option value="ml-IN">Malayalam (IN)</option>
                  <option value="hi-IN">Hindi (IN)</option>
                  {/* Add more language options as needed */}
                </select>
              </div>
              <div style={{display: 'flex', gap: 30, alignItems: 'center', justifyContent: 'center'}}>
              <button 
                onClick={() => onStartRecording(selectedLanguage)} 
                style={{ marginTop: '15px', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#007BFF', color: '#fff' }}
              >
                Start
              </button>
              <button 
                onClick={() => setShowListeningModal(false)} 
                style={{ marginTop: '15px', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#007BFF', color: '#fff' }}
              >
                Close
              </button>
              </div>
            </>
          )}
  
          {isRecording && (
            <>
              <p>{text}</p>
              <button 
                onClick={onStopRecording} 
                style={{ marginTop: '15px', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#DC3545', color: '#fff' }}
              >
                Stop
              </button>
            </>
          )}
        </div>
      </div>
    );
  };
  
  
  
  
  
  
  

  
  const Modal = ({ isOpen, onClose, fileInfo }) => {
    const [copySuccess, setCopySuccess] = useState(false); // State to track if the link was copied successfully
  
    const copyLinkToClipboard = () => {
      if (fileInfo && fileInfo.url) {
        navigator.clipboard.writeText(fileInfo.url)
          .then(() => {
            setCopySuccess(true); // Show "Link copied" message
          })
          .catch((err) => {
            console.error('Failed to copy link to clipboard:', err);
          });
      }
    };
  
    useEffect(() => {
      if (copySuccess) {
        // Hide the "Link copied" message after 5 seconds
        const timer = setTimeout(() => setCopySuccess(false), 50000);
        return () => clearTimeout(timer); // Clear the timer when component unmounts or state changes
      }
    }, [copySuccess]);
  
    if (!isOpen) return null;
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <p>
            {fileInfo.type === 'PDF' 
              ? 'PDF link is ready. Click "Copy Link" to copy it.' 
              : 'Doc link is ready. Click "Copy Link" to copy it.'}
          </p>
          <p style={{color: 'red'}}>Note: for better usage paste link in chrome browser</p>
          
          {/* Display the "Link copied" message when the copy is successful */}
          {copySuccess && <p style={{ color: 'blue' }}>Link copied successfully!</p>}
          
          <div style={{ display: 'flex', alignItems: 'center', gap: 15, justifyContent: 'center' }}>
            <button onClick={copyLinkToClipboard}>Copy Link</button>
            <button onClick={onClose}>OK</button>
          </div>
        </div>
      </div>
    );
  };
  

  return (
    <div className="chat-container">
      <div className="chat-header">
        {/* Menu and Tool buttons */}
        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', cursor: 'pointer' }} onClick={toggleMenu}>
          <img src={menuIcon} alt="Menu" className="menu-icon" />
          <span>Menu</span>
        </div> */}
        <button className="menu-button" onClick={handleMergePdfClick}>
              {/* <img src={tools} alt="Tools" style={{width: '20px'}} /> */}
              <span>Tools</span>
        </button>
        {/* <button className="menu-button" onClick={handleClearClick}>
        <span>Clear</span>
      </button> */}
        <div>
      <button className="menu-button" onClick={handleSupportClick}>
        <span>Support</span>
      </button>
    </div>
        <button className="menu-button" onClick={handleLogout}>
              {/* <img src={logoutIcon} alt="Logout" style={{width: '20px'}} /> */}
              <span>Logout</span>
        </button>

        {/* {isMenuOpen && (
          <div className="side-menu">
            <button className="tools-button" onClick={handleMergePdfClick}>
              <img src={tools} alt="Tools" />
              <span>Tools</span>
            </button>
            <button className="tools-button" onClick={handleLogout}>
              <img src={logoutIcon} alt="Logout" />
              <span>Logout</span>
            </button>
          </div>
        )} */}
      </div>
  
      <div className="chat-content">
        {/* Display messages */}
        {messages.map((message, index) => (
          <React.Fragment key={index}>
            {isNewDay(message, messages[index - 1]) && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="date-separator">{formatDate(message.timestamp)}</div>
              </div>
            )}
            <div className={`message ${message.sender === 'user' ? 'user-message' : 'bot-message'}`}>
              {/* File messages handling */}
              {message.fileType ? (
                <div className="file-message">
                  <span>{message.sender === 'user' ? 'You' : 'Lexi'} sent a file:</span>
                  <a href={message.fileUrl} target="_blank" rel="noopener noreferrer" className="file-link">
                    <img src={message.filePreview} alt={message.fileName} className="file-icon" />
                    {message.fileName}
                  </a>
                </div>
              ) : (
                /* Normal text messages */
                <div className="message-content">
                  {typeof message.text === 'string'
                 ? message.text.split('\n').map((line, i) => (
            <span key={i} dangerouslySetInnerHTML={{ __html: linkify(line) }}></span>
                ))
              : 'Invalid message content'}


                </div>
              )}
  
              <div className="message-footer">
                <small>{new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</small>
                {message.sender !== 'user' && (
                  <>
                    {/* Actions for Lexi messages */}
                    <button onClick={() => toggleSpeech(message.text)} className="action-button">
                      <img src={isSpeaking && currentSpeechText === message.text ? muteIcon : speakerIcon} alt="Speaker icon" />
                    </button>
                    <button onClick={() => generatePDF(message)} className="action-button">
                      <img src={pdfIcon} alt="PDF icon" />
                    </button>
                    <button onClick={() => generateDOCX(message)} className="action-button">
                      <img src={docxIcon} alt="DOCX icon" />
                    </button>
                  </>
                )}
                {/* Copy button */}
                <button onClick={() => copyToClipboard(message.text)} className="action-button">
                  <img src={copyIcon} alt="Copy icon" />
                </button>
              </div>
            </div>
          </React.Fragment>
        ))}
        <div ref={scrollViewRef} />
      </div>
  
      {isTyping && (
        <div className="typing-indicator">
          <span>Lexi is typing...</span>
        </div>
      )}
  
      <div className="input-area">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
          <div style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
          <label className="file-upload-label" onClick={handleAttachmentClick}>
            <img src={fileUploadIcon} alt="Upload" className="file-upload-icon" />
          </label>
          
            {/* <input
              id="file-upload"
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="file-input"
              accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple
            /> */}

            <input
              id="file-upload"
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="file-input"
              accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple
              style={{ display: 'none' }} // Hide the input element
            />
            <AttachmentOptionsModal
            isOpen={showAttachModal}
            onClose={() => setShowAttachModal(false)}
            onLexiAiClick={handlePdfClick}
            onContinueClick={handleContinueOption}
            />
            <button onClick={toggleRecording} className="mic-button" style={{ marginLeft: 15 }}>
              <img src={isRecording ? micMuteIcon : micIcon} alt="Mic" className="mic-icon" />
            </button>
          </div>
  
          {/* Show selected files */}
          {selectedFiles.map((file, index) => {
            const trimmedFileName = file.name.length > 20 ? `${file.name.substring(0, 15)}...` : file.name;
  
            return (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                <span className="file-name" style={{ border: '1px solid #ccc', padding: '5px 10px', borderRadius: '10px', backgroundColor: '#fff' }}>
                  {trimmedFileName}
                </span>
                <button className="clear-button" onClick={() => clearSelectedFile(index)} style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '5px', marginRight: '15px' }}>
                  <FontAwesomeIcon icon={faTimesCircle} size="lg" color="#FF0000" />
                </button>
              </div>
            );
          })}
        </div>
  
        <textarea
          className="text-input"
          placeholder={isConverting ? 'Converting text...' : isRecording ? 'Converting text...' : 'Message Lexi...'}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault(); // Prevent adding a new line
              handleSend(); // Trigger the send function
            }
          }}
        />
        {/* <button onClick={handleSend} className="send-button" disabled={disableSendButton}>
        <img src={sendIcon} alt="Upload" className="file-upload-icon" />
        </button> */}
          {showSendButton && ( // Conditionally render the send button
          <button onClick={handleSend} className="send-button">
            <img src={sendIcon} alt="Upload" className="file-upload-icon" />
          </button>
        )}
      </div>
  
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} fileInfo={fileInfo} />
      <ListeningModal 
  isOpen={showListeningModal} 
  text={modalText} 
  onStartRecording={handleStartRecording} 
  onStopRecording={handleStopRecording}
  isRecording={isRecording} 
  selectedLanguage={selectedLanguage} 
  setSelectedLanguage={setSelectedLanguage} 
/>


    </div>
  );
};  

const checkIfLegalOrRealTimeQuery = (query) => {
  const legalKeywords = [
    'law', 'legal', 'compliance', 'contract', 'litigation',
    'tax law', 'corporate law', 'intellectual property', 'auditing', 'judgement',
    'regulation', 'ethics', 'court', 'real-time', 'latest', 'case', 'cases', 'address',
  ];

  return legalKeywords.some(keyword => query.toLowerCase().includes(keyword));
};

const getConversationHistory = (messages, maxHistory = 5) => {
  // Select the last `maxHistory` messages to include in the conversation context
  const relevantMessages = messages.slice(maxHistory).map((msg) => {
    return `${msg.sender === 'user' ? 'User' : 'Lexi'}: ${msg.text}`;
  });
  
  return relevantMessages.join('\n');
};

export default ChatGPT;